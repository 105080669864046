import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component:() => import('@/views/map.vue')
    },
    {
        path: '/report/:zj',
        name: 'report',
        component:() => import('@/views/report.vue')
    }
]
const router = createRouter({
    mode: 'hash',
    history: createWebHashHistory(),
    routes
})
export default router
