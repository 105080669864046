import { createApp } from 'vue'
import App from './App.vue'
import 'vant/lib/index.css'
import {
    Toast,
    List,
    ActionSheet,
    Cell,
    CellGroup,
    Tab,
    Tabs
} from 'vant'
import router from '@/router'
createApp(App)
    .use(Toast)
    .use(ActionSheet)
    .use(List)
    .use(Cell)
    .use(CellGroup)
    .use(Tab)
    .use(Tabs)
    .use(router)
    .mount('#app')
